import Ebook from '@pretto/website/src/components/Ebook'
import { HERO_RATES, ROW_RATES } from '@pretto/website/src/config/rowsNames'
import { PostContentProvider } from '@pretto/website/src/postTypes/context/PostContent'

import loadable from '@loadable/component'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

const BaseDocumentaire = loadable(() => import('../../../../templates/baseDocumentaire/baseDocumentaire'), {
  resolveComponent: components => components.BaseDocumentaire,
})

const Blocs = loadable(() => import('../../../../templates/blocs/blocs'), {
  resolveComponent: components => components.Blocs,
})

const Defaut = loadable(() => import('../../../../templates/defaut/defaut'), {
  resolveComponent: components => components.Defaut,
})

const EnSavoirPlus = loadable(() => import('../../../../templates/enSavoirPlus/enSavoirPlus'), {
  resolveComponent: components => components.EnSavoirPlus,
})

const Home = loadable(() => import('@pretto/website/src/templates/home/HomeRows'), {
  resolveComponent: components => components.HomeBlocs,
})

const Podcast = loadable(() => import('../../../../templates/podcast/podcast'), {
  resolveComponent: components => components.Podcast,
})

export const Dispatcher = props => {
  const templateUseRowRates =
    props.data.wpPage.template.__typename === 'WpTemplate_Blocs' &&
    (props.data.wpPage.template.about.hero?.[0].__typename === HERO_RATES ||
      props.data.wpPage.template.about.rows?.some(row => row.__typename === ROW_RATES))

  return (
    <PostContentProvider postData={props.data.wpPage} displayTodayAsPublicationDate={templateUseRowRates}>
      <DispatcherContent {...props} />
    </PostContentProvider>
  )
}

const DispatcherContent = ({
  data: { allTemplateBlocsTag, articles, articlesCategory, rates, secondaryMenu, wp, wpMenu, wpPage },
  pictoComponents,
}) => {
  const ebookComponent = useMemo(() => <Ebook />, [])

  switch (wpPage.template.__typename) {
    case 'WpDefaultTemplate':
      return <Defaut footerComponent={ebookComponent} data={wpPage} />

    case 'WpTemplate_Blocs':
      return (
        <Blocs
          data={wpPage}
          menu={wpMenu}
          pictoComponents={pictoComponents}
          rates={rates}
          ratesSettings={wp.acfOptionsRatesSettings.ratesSettings}
          secondaryMenu={secondaryMenu}
          tagsData={allTemplateBlocsTag}
        />
      )

    case 'WpTemplate_BaseDocumentaire':
      return <BaseDocumentaire data={wpPage} />

    case 'WpTemplate_EnSavoirPlusdprci':
      return <EnSavoirPlus data={wpPage} />

    case 'WpTemplate_Home':
      return (
        <Home
          data={wpPage}
          rates={rates.data.marketRatesProfileRegionLatest}
          articlesProps={{ articles, articlesCategory }}
        />
      )

    case 'WpTemplate_Podcast':
      return <Podcast data={wpPage} />

    default:
      return null
  }
}

DispatcherContent.propTypes = {
  data: PropTypes.shape({
    allTemplateBlocsTag: PropTypes.object,
    rates: PropTypes.object,
    wpMenu: PropTypes.object,
    wpPage: PropTypes.shape({
      comments: PropTypes.shape({ nodes: PropTypes.array }).isRequired,
      databaseId: PropTypes.number,
      template: PropTypes.shape({
        __typename: PropTypes.oneOf([
          'WpTemplate_Blocs',
          'WpTemplate_BaseDocumentaire',
          'WpTemplate_EnSavoirPlusdprci',
          'WpTemplate_Home',
          'WpTemplate_Podcast',
        ]).isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  pictoComponents: PropTypes.arrayOf(PropTypes.elementType).isRequired,
}

Dispatcher.propTypes = DispatcherContent.propTypes
