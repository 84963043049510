import EbookBanner from '@pretto/bricks/website/shared/components/EbookBanner'

import PropTypes from 'prop-types'
import { memo, useState } from 'react'

import { trackAction } from '../../utilities'

const Ebook = ({ standAlone }) => {
  const [subscribed, setSubscribed] = useState(false)
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (email, optin) => {
    setLoading(true)
    const data = JSON.stringify({
      Ebook: 'banner wordpress',
      Email: email,
      Optin: optin,
    })
    const promise = await fetch('https://hooks.zapier.com/hooks/catch/2061324/l55tds/', { body: data, method: 'POST' })
    const res = await promise.json()
    if (res.status === 'success') {
      trackAction('Ebook Email Submitted')
      setSubscribed(true)
    }
    setLoading(false)
  }

  return <EbookBanner standAlone={standAlone} subscribed={subscribed} loading={loading} onSubscribe={handleSubmit} />
}

Ebook.propTypes = {
  standAlone: PropTypes.bool,
}
Ebook.defaultProps = {
  standAlone: true,
}

export default memo(Ebook)
