import { breakpoints, g } from '@pretto/bricks/components/layout'
import styled from 'styled-components'

export const Description = styled.div`
  margin-bottom: ${g(3)};
`

export const Form = styled.form``

export const Email = styled.div`
  position: relative;
`
export const Button = styled.div`
  position: absolute;
  top: ${g(0.5)};
  right: ${g(0.5)};
  bottom: ${g(0.5)};
`

export const Optin = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${g(3)};
`
export const Label = styled.label`
  cursor: pointer;
  text-align: left;
`
export const Content = styled.div`
  @media screen and (min-width: ${breakpoints.tablet}) {
    position: absolute;
    bottom: ${g(3)};
    left: ${g(3)};
  }
`
